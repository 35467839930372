<template>
  <div id="blog-article-card">
    <div class="blog-article-cover"
         :style="{backgroundImage: 'url(' + article.coverImage + ')'}">
      <div
        class="blog-article-title"
        @click="readArticle(article.articleId)">
        <span>{{ article.title }}</span>
      </div>
    </div>
    <div class="blog-article-sub-message">{{ article.subMessage }}</div>
    <div class="blog-article-category">
      <i class="el-icon-myblog-folder"></i>
      <a @click="toCategory(article.category.categoryId)">{{ article.category.name }}</a>
    </div>
    <div class="blog-article-tags" v-if="article.tags.length > 0">
      <i class="el-icon-myblog-discount"></i>
      <a
        class="blog-article-tags-link"
        v-for="(tag, index) in article.tags"
        :key="index"
        @click="toTag(tag.tagId)">
        #{{ tag.name }}
      </a>
    </div>
    <div class="blog-article-info">
      <el-tooltip
        class="item"
        effect="dark"
        :content="showContent"
        :open-delay.number="openDelay"
        placement="top">
        <span
          @click="switchTime">
          <i :class="showTimeIcon"></i>{{ showTime }}
        </span>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        :content="'阅读了 ' + article.pageviews + ' 次'"
        :open-delay.number="openDelay"
        placement="top">
        <span>
          <i class="el-icon-myblog-browse"></i>{{ article.pageviews }}
        </span>
      </el-tooltip>
    </div>
    <hr>
    <div class="blog-article-read-more">
      <a @click="readArticle(article.articleId)">
        阅读全文 <i class="el-icon-myblog-more"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'blog-article-card',
  props: ['article'],
  data () {
    return {
      showPublishTime: true,
      showTime: this.article.publishTime,
      showContent: '发布于 ' + this.article.publishTime,
      showTimeIcon: 'el-icon-myblog-calendar',
      openDelay: 300
    }
  },
  methods: {
    switchTime () {
      this.showPublishTime = !this.showPublishTime
      if (this.showPublishTime) {
        this.showTime = this.article.publishTime
        this.showContent = '发布于 ' + this.article.publishTime
        this.showTimeIcon = 'el-icon-myblog-calendar'
      } else {
        this.showTime = this.article.modifiedTime
        this.showContent = '编辑于 ' + this.article.modifiedTime
        this.showTimeIcon = 'el-icon-myblog-edit'
      }
    },
    readArticle (articleId) {
      if (articleId) {
        this.$router.push({
          name: 'Article',
          params: { articleId: articleId }
        })
      }
    },
    toCategory (categoryId) {
      this.$router.push({
        name: 'CategoryArticleList',
        params: { categoryId: categoryId }
      })
    },
    toTag (tagId) {
      this.$router.push({
        name: 'TagArticleList',
        params: { tagId: tagId }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
#blog-article-card
  position: relative
  padding: 20px 20px 10px 20px
  margin-bottom: 30px
  box-shadow: 0 0 5px 0 rgba(38, 42, 48, .1)
  line-height: 1.2
  text-align: left
  &:last-child
    margin-bottom: 0

.blog-article-cover
  width: 100%
  height: 100%
  background-position: 50%
  background-size: cover
  position: relative
  padding-bottom : 53%
  &:before
    background-color: rgba(0, 0, 0, .3)
    content: ' '
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 2
  .blog-article-title
    font-size: 24px
    display: inline-block
    vertical-align: middle
    text-align: center
    z-index: 3
    width: 100%
    font-weight: 700
    color: #fff
    padding: 10px
    position absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    span
      cursor: pointer

.blog-article-sub-message
  color: #5c5c5c
  border-left: 3px solid #666
  padding-left: 10px
  margin: 15px 0
  font-size: 12pt

.blog-article-category
  color: #5c5c5c
  font-size: 11pt
  margin: 15px 0
  cursor: pointer
  i
    margin-right: 5px

.blog-article-tags
  color: #5c5c5c
  font-size: 11pt
  margin: 15px 0
  i
    margin-right: 5px
  a
    margin: 0
    padding: 0
    span
      margin-left: -4px
  .blog-article-tags-link
    margin-right: 5px
    cursor: pointer
    &:last-child
      margin-right: 0

.blog-article-info
  font-size: 10pt
  color: #737373
  margin: 15px 0
  span
    margin-right: 10px
    cursor: pointer
    &:last-child
      margin-right: 0
    i
      margin-right: 5px

.blog-article-read-more
  text-align: right
  font-size: 10pt
  cursor: pointer
  height: 40px
  line-height: 40px
  a
    color: rgba(100, 100, 100, 0.6)
    width: 100%
    height: 100%
    display: block
    padding-right: 5px
    transition: color .3s
    &:hover
      color: rgba(100, 100, 100, 0.8)
hr
  border: 0
  height: 1px
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.01))
</style>
