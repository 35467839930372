<template>
  <div id="blog-home" v-loading="loading">
    <blog-article-card
      v-for="(article, index) in articles"
      :key="index"
      :article="article" />
    <el-pagination
      v-if="articlesTotal > 0"
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      :total="articlesTotal">
    </el-pagination>
  </div>
</template>

<script>
import BlogArticleCard from '@/components/BlogArticleCard'

export default {
  name: 'blog-home',
  components: {
    BlogArticleCard
  },
  data () {
    return {
      articles: [],
      articlesTotal: 0,
      currentPage: 1,
      pageSize: 5,
      loading: true
    }
  },
  methods: {
    handleCurrentChange (val) {
      this.currentPage = val
      this.getArticlesList()
    },
    getArticlesList () {
      this.articles = []
      this.$http({
        url: this.$api.BLOG_GET_ARTICLES_LIST_URL,
        method: 'GET',
        params: {
          page: this.currentPage,
          pageSize: this.pageSize
        }
      }).then(response => {
        if (response.status === 'success') {
          this.articlesTotal = response.data.total
          for (const item of response.data.result) {
            this.articles.push(item)
          }
          this.loading = false
        } else {
          this.$message.error('服务器好像出错了...')
          this.loading = false
        }
      }).catch(() => {
        this.$message.error('哎呀...网络出错了，刷新一下吧')
        this.loading = false
      })
    }
  },
  created () {
    this.getArticlesList()
  }
}
</script>

<style lang="stylus" scoped>
#blog-home
  min-height: calc(100vh - 160px)
</style>
